<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useMutation } from '@tanstack/vue-query';
import * as yup from 'yup';
import { usersApi } from '@/api/users';
import type { AxiosError } from 'axios';

const { t } = useI18n();

const validationSchema = yup.object({
  email: yup.string().email(t('recoverPassword.emailInvalid')).required(t('recoverPassword.emailRequired')),
});

const { handleSubmit, values, errors, defineField } = useForm({ validationSchema });

const [email, emailAttrs] = defineField('email');

const { mutate: recoverPassword, isError, error } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => usersApi.recoverPassword(values.email),
    onSuccess: () => { window.location.href = '/users/sign_in'; },
  },
);

const onSubmit = handleSubmit(() => recoverPassword());

const errorText = computed(() => {
  if (isError.value) {
    return (error.value?.response?.data as { [key: string]: string }).error;
  }

  return '';
});

</script>

<template>
  <div>
    <h3 class="mb-2 text-2xl font-semibold">
      {{ t('recoverPassword.title') }}
    </h3>
    <p class="mb-8 text-gray-400">
      {{ t('recoverPassword.subtitle') }}
    </p>

    <v-form @submit.prevent="onSubmit">
      <v-text-field
        v-model="email"
        v-bind="emailAttrs"
        variant="outlined"
        :label="t('recoverPassword.email')"
        :error-messages="errors.email"
      />
      <div class="mt-4 align-center d-flex justify-space-between">
        <v-btn
          color="primary"
          type="submit"
        >
          {{ t('recoverPassword.submit') }}
        </v-btn>
        <v-btn
          variant="text"
          color="primary"
          size="x-small"
          :href="'/users/sign_in'"
        >
          {{ t('recoverPassword.backToLogin') }}
        </v-btn>
      </div>
    </v-form>
    <v-alert
      v-if="isError"
      class="mt-10 rounded"
      :type="'error'"
    >
      {{ errorText || t('userSession.defaultError') }}
    </v-alert>
  </div>
</template>
