<script setup lang="ts">
import { ref } from 'vue';
import { type Shift } from '@/api/shifts';
import ShiftShowReportImages from '@/components/shifts/actions/shift-show-report-images.vue';
import ShiftShowReportTexts from '@/components/shifts/actions/shift-show-report-texts.vue';
import ShiftShowSummary from '@/components/shifts/actions/shift-show-summary.vue';

const props = defineProps<{ shift: Shift }>();

const emit = defineEmits(['close']);

const tab = ref('reportImages');
</script>
<template>
  <v-card class="pa-4">
    <v-card>
      <v-tabs
        v-model="tab"
        color="primary"
      >
        <v-tab
          value="reportImages"
          class="rounded-pill text-body-1"
        >
          Registros Visuales
        </v-tab>
        <v-tab
          value="reportTexts"
          class="rounded-pill text-body-1"
        >
          Registros Escritos
        </v-tab>
        <v-tab
          value="summary"
          class="rounded-pill text-body-1"
        >
          Resumen
        </v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item value="reportImages">
          <shift-show-report-images :shift="props.shift" />
        </v-window-item>
        <v-window-item value="reportTexts">
          <shift-show-report-texts :shift="props.shift" />
        </v-window-item>
        <v-window-item value="summary">
          <shift-show-summary :shift="props.shift" />
        </v-window-item>
      </v-window>
    </v-card>
    <v-card-actions>
      <v-spacer />
      <v-btn
        text="Cerrar"
        @click="emit('close')"
      />
    </v-card-actions>
  </v-card>
</template>
