import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useShiftsStore = defineStore('shifts', () => {
  const currentPatientId = ref<number | undefined>(undefined);
  const currentCaregiverId = ref<number | undefined>(undefined);
  const currentStartDateTimeFilter = ref<string | undefined>(undefined);
  const currentOffset = ref<number>(0);
  const currentGroupByClass = ref<string | undefined>(undefined);
  const currentCaregiverCareerIn = ref<string[]>([]);

  return {
    currentPatientId,
    currentCaregiverId,
    currentStartDateTimeFilter,
    currentOffset,
    currentGroupByClass,
    currentCaregiverCareerIn,
  };
}, { persist: true });
