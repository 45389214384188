<script setup lang="ts">
import { computed } from 'vue';
import { type Patient } from '@/api/patients';
import { shiftsApi } from '@/api/shifts';
import { useQuery } from '@tanstack/vue-query';
import GoBackButton from '@/components/go-back-button.vue';
import PatientShiftShow from './patient-shift-show.vue';

const props = defineProps<{
  patient: Patient
}>();

const headers = [
  { title: 'Fecha', value: 'humanDate' },
  { title: 'Horario', value: 'humanTime' },
  { title: 'Profesional', value: 'caregiverFullName' },
  { title: 'Registros', value: 'actions', sortable: false },
];

const { data: shifts } = useQuery({
  queryKey: ['shifts', props.patient.id],
  queryFn: async () => {
    const data = await shiftsApi.index(`patient_id_eq=${props.patient.id}&start_date_time_filter=until_today`);

    return [...data].reverse();
  },
});

const lastShift = computed(() => shifts.value && shifts.value[0]);
</script>
<template>
  <v-container>
    <go-back-button :path="`/patients/${patient.id}`" />
    <v-row class="mt-2">
      <v-col>
        <h2 class="mb-4 text-h5">
          Ficha Clínica de Paciente {{ patient.fullName }}
        </h2>
        <v-card
          v-if="lastShift"
          class="mb-4"
        >
          <v-card-title class="text-h6">
            Última Atención
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <p class="mt-2">
                  <strong>Fecha:</strong> {{ lastShift.humanDate }}
                </p>
                <p class="mt-2">
                  <strong>Horario:</strong> {{ lastShift.humanTime }}
                </p>
                <p class="mt-2">
                  <strong>Profesional:</strong> {{ lastShift.caregiverFullName }}
                </p>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <p class="mt-2">
                  <strong>Cantidad de registros visuales:</strong>
                  {{ lastShift.reportImages?.length || 0 }}
                </p>
                <p class="mt-2">
                  <strong>Cantidad de registros de texto:</strong>
                  {{ lastShift.reportTexts?.length || 0 }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="lastShift.reportImagesSummary">
              <h6 class="ml-3 text-h6">
                Resumen
              </h6>
              <p class="mt-2">
                {{ lastShift.reportImagesSummary }}
              </p>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-data-table
            class="elevation-1"
            no-data-text="No hay turnos ni atenciones registradas"
            :headers="headers"
            :header-props="{ class: 'font-weight-bold' }"
            :items="shifts"
            :items-per-page="10"
          >
            <template #top>
              <h6 class="ma-4 text-h6">
                Turnos y Atenciones
              </h6>
            </template>
            <template #item.actions="{ item: shift }">
              <v-dialog
                :key="shift.id"
                max-width="800"
                scrollable
              >
                <template #activator="{ props: activatorProps }">
                  <v-btn
                    v-bind="activatorProps"
                    icon="mdi-eye"
                    size="small"
                  />
                </template>
                <template #default="{ isActive }">
                  <patient-shift-show
                    :shift="shift"
                    @close="isActive.value = false"
                  />
                </template>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
