import { api } from './index';

export type User = {
  id?: number;
  email: string;
  name?: string;
  lastName?: string;
  companyId?: number;
  companyName?: string;
  companyUsesIdCode: boolean;
  companyChargesAndPaysPerHours: boolean;
  companyProfessionalViewEnabled: boolean;
}

export interface UserForm extends User {
  password: string;
  passwordConfirmation?: string;
  resetPasswordToken?: string;
}

export type SerializedUser = User;

export const usersApi = {
  create(user: Partial<UserForm>): Promise<SerializedUser> {
    return api({
      method: 'post',
      url: '/users',
      data: { user },
    }).then(response => response.data.user);
  },
  logout() {
    return api({
      method: 'delete',
      url: '/users/sign_out',
    }).then(res => res.data);
  },
  login(user: Partial<UserForm>): Promise<SerializedUser> {
    return api({
      method: 'post',
      url: '/users/sign_in',
      data: { user },
    }).then(res => res.data.user);
  },
  recoverPassword(email: string) {
    return api({
      method: 'post',
      url: '/users/password',
      data: { user: { email } },
    }).then(res => res.data);
  },
  editPassword(user: Partial<UserForm>) {
    return api({
      method: 'put',
      url: '/users/password',
      data: { user },
    }).then(res => res.data);
  },
};
