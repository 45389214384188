<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { useMutation } from '@tanstack/vue-query';
import * as yup from 'yup';
import { usersApi } from '@/api/users';
import type { AxiosError } from 'axios';

const { t } = useI18n();

const MIN_PASSWORD_LENGTH = 8;

const validationSchema = yup.object({
  password: yup.string()
    .min(MIN_PASSWORD_LENGTH, t('editPassword.passwordMinLength'))
    .required(t('editPassword.passwordRequired')),
  passwordConfirmation: yup.string()
    .min(MIN_PASSWORD_LENGTH, t('editPassword.passwordMinLength'))
    .required(t('editPassword.passwordConfirmationRequired'))
    .oneOf([yup.ref('password')], t('editPassword.passwordConfirmationMismatch')),
});

const { handleSubmit, values, errors, defineField } = useForm({ validationSchema });

const [password, passwordAttrs] = defineField('password');
const [passwordConfirmation, passwordConfirmationAttrs] = defineField('passwordConfirmation');

const urlParams = new URLSearchParams(window.location.search);
const recoveryToken = urlParams.get('reset_password_token');

const { mutate: editPassword, isError, error } = useMutation<unknown, AxiosError>(
  {
    mutationFn: () => usersApi.editPassword({
      ...values,
      resetPasswordToken: recoveryToken || '',
    }),
    onSuccess: () => { window.location.href = '/users/sign_in?successful_password_update=true'; },
  },
);

const onSubmit = handleSubmit(() => editPassword());

const errorText = computed(() => {
  if (isError.value) {
    return (error.value?.response?.data as { [key: string]: string }).error;
  }

  return '';
});

</script>

<template>
  <div class="w-auto">
    <h3 class="mb-2 text-2xl font-semibold">
      {{ t('editPassword.title') }}
    </h3>
    <p class="mb-8 text-gray-400">
      {{ t('editPassword.subtitle') }}
    </p>

    <v-form @submit.prevent="onSubmit">
      <v-text-field
        v-model="password"
        v-bind="passwordAttrs"
        type="password"
        variant="outlined"
        :label="t('editPassword.password')"
        :error-messages="errors.password"
      />
      <v-text-field
        v-model="passwordConfirmation"
        v-bind="passwordConfirmationAttrs"
        type="password"
        variant="outlined"
        :label="t('editPassword.passwordConfirmation')"
        :error-messages="errors.passwordConfirmation"
      />
      <div class="mt-4 align-center d-flex justify-space-between">
        <v-btn
          color="primary"
          type="submit"
        >
          {{ t('editPassword.submit') }}
        </v-btn>
        <v-btn
          variant="text"
          color="primary"
          size="x-small"
          :href="'/users/sign_in'"
        >
          {{ t('editPassword.backToLogin') }}
        </v-btn>
      </div>
    </v-form>
    <v-alert
      v-if="isError"
      class="mt-4 rounded"
      :type="'error'"
    >
      {{ errorText || t('userSession.defaultError') }}
    </v-alert>
  </div>
</template>
